import RollbarProvider from 'providers/RollbarProvider';
import PosthogProvider from 'providers/PosthogProvider';
import ReactQueryProvider from 'providers/ReactQueryProviders';
import FacebookPixel from 'components/FacebookPixel';

const App = ({ Component, pageProps }) => {
  return (
    <RollbarProvider>
      <PosthogProvider>
        <ReactQueryProvider pageProps={pageProps}>
          <FacebookPixel />
          <Component {...pageProps} />
        </ReactQueryProvider>
      </PosthogProvider>
    </RollbarProvider>
  );
};

export default App;
