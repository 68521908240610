import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
    persistence: 'localStorage',
    autocapture: false,
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true,
      },
    },
    loaded: (posthog) => {
      const env = process.env.NEXT_PUBLIC_VERCEL_ENV;

      if (env !== 'production' && env !== 'preview') {
        posthog.debug();
      }
    },
  });
}

const PosthogProvider = ({ children }) => {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
};

export default PosthogProvider;
