import Rollbar from 'rollbar';
import { Provider, ErrorBoundary } from '@rollbar/react';

const env = process.env.NEXT_PUBLIC_VERCEL_ENV;
const rollbarConfig = {
  accessToken: process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: env !== 'development',
  payload: {
    environment: env ? env : 'development',
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
        guess_uncaught_frames: true,
      },
    },
  },
};

export const rollbar = new Rollbar(rollbarConfig);

const RollbarProvider = ({ children }) => {
  return (
    <Provider instance={rollbar}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Provider>
  );
};

export default RollbarProvider;
